<template>
    <div :class="getClass">
      <div class="splash-screen-title">
        <v-btn class="play-again-button" size="x-large" color="#1565C0" variant="tonal" @click="playAgain"><h1>Play again!</h1></v-btn>
        <div class="animate__animated animate__bounceInUp">
          Thanks
        </div>
        <div class="animate__animated animate__bounceInDown animate__delay-0pt5s">
          For
        </div>
        <div class="animate__animated animate__bounceInLeft animate__delay-1s">
          Playing!
        </div>
      </div>
      <div class="lottie-beer animate__animated animate__bounceIn animate__delay-2pt5s">
        <div ref="container"></div>
      </div>
      </div>
  </template>
  
  <script>
  import Lottie from "lottie-web";
  export default {
    name: "SplashScreen",
    mounted() {
      const options = {
        container: this.$refs.container,
        path: "./lottie/beer2.json",
        autoplay: false,
        loop: true
      };
      const animation = Lottie.loadAnimation(options);
      setTimeout(() => {
        animation.play();
      }, 2000);
      this.splashClass = "splash-screen animate__animated animate__zoomIn";
    },
    data() {
        return {
            splashClass: "splash-screen hidden"
        }
    },
    computed: {
        getClass(){
            return this.splashClass
        }
    },
    methods: {
        playAgain(){
            console.log("play again")
            window.location.reload();
        }
    }
  };
  </script>
  
  <style lang="scss">
  .splash-screen {
    font-family: 'Palitoon', sans-serif !important;
    position: absolute;
    background: #d0f5f0;
    width: 100vw;
    height: 100vh;
    top: 45%; /* Move the top of the div to the vertical center */
    left: 50%; /* Move the left of the div to the horizontal center */
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  
  .splash-screen-title {
    font-size: 100px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    > div {
      margin: 0 10px;
      flex-basis: 100%;
      display: flex;
      justify-content: center;
      line-height: 1;
    }
    color: #fff;
    text-shadow: 2px 0 0 #000, 0 -2px 0 #000, 0 2px 0 #000, -2px 0 0 #000;
  }
  
  .lottie-beer {
    margin-top: 20px;
    width: 400px;
    height: 200px;
    > div {
      margin-top: -125px;
    }
  }
  
  .animate__delay-0pt5s {
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
  }
  
  .animate__delay-1pt5s {
    -webkit-animation-delay: 1.5s;
    animation-delay: 1.5s;
    -webkit-animation-delay: 1.5s;
    animation-delay: 1.5s;
  }
  
  .animate__delay-2pt5s {
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s;
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s;
  }
  
  .animate__delay-3pt5s {
    -webkit-animation-delay: 3.5s;
    animation-delay: 3.5s;
    -webkit-animation-delay: 3.5s;
    animation-delay: 3.5s;
  }
  
  .animate__delay-4pt5s {
    -webkit-animation-delay: 4.5s;
    animation-delay: 4.5s;
    -webkit-animation-delay: 4.5s;
    animation-delay: 4.5s;
  }
  
  .animate__delay-5pt5s {
    -webkit-animation-delay: 5.5s;
    animation-delay: 5.5s;
    -webkit-animation-delay: 5.5s;
    animation-delay: 5.5s;
  }
  
  .waviy {
    position: relative;
    -webkit-box-reflect: below -20px linear-gradient(transparent, rgba(0,0,0,.2));
    font-size: 100px;
    line-height: 1 !important;
  }
  
  .waviy span {
    position: relative;
    display: inline-block;
    color: #fff;
    text-transform: uppercase;
    animation: waviy 1s infinite;
    animation-delay: calc(.1s * var(--i));
  }
  @keyframes waviy {
    0%,40%,100% {
      transform: translateY(0)
    }
    20% {
      transform: translateY(-20px)
    }
  }

  .play-again-button {
  margin: 0 auto;
}
  </style>
  