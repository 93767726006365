<template>
  <div class="splash-screen" @click="playGame">
    <div class="animate__animated animate__fadeOutLeft animate__delay-5s">

    <div class="splash-screen-title">
      <div class="animate__animated animate__bounceInUp">
        Who
      </div>
      <div class="animate__animated animate__bounceInDown animate__delay-0pt5s">
        Buys
      </div>
      <div class="animate__animated animate__bounceInLeft animate__delay-1s">
        The
      </div>
      <div class="animate__animated animate__bounceInRight animate__delay-1pt5s">
        Round
      </div>
        <div ref="ellipsis" class="animate__animated animate__fadeIn animate__delay-2s">
          <span style="--i:1">.</span>
          <span style="--i:2">.</span>
          <span style="--i:3">.</span>
      </div>
    </div>
    <div class="lottie-beer animate__animated animate__bounceIn animate__delay-2pt5s">
      <div ref="container"></div>
    </div>

  </div>  
  <div ref="tapToBegin" class="tap-to-begin">
      <div class="animate__animated animate__fadeIn animate__delay-5pt5s">
        Tap
      </div>
      <div class="animate__animated animate__fadeIn animate__delay-5pt5s">
        To
      </div>
      <div class="animate__animated animate__fadeIn animate__delay-5pt5s">
        Begin
      </div>
    </div>    
    </div>
</template>

<script>
import Lottie from "lottie-web";
export default {
  name: "SplashScreen",
  mounted() {
    const options = {
      container: this.$refs.container,
      path: "./lottie/beer.json",
      autoplay: false,
      loop: false
    };
    const animation = Lottie.loadAnimation(options);
    setTimeout(() => {
      animation.play();
    }, 3000);

    setTimeout(() => {
      this.addClassToEllipsis();
    }, 2000);

    setTimeout(() => {
      if(this.$refs.tapToBegin){
        this.$refs.tapToBegin.classList.remove("hidden")
      }
    }, 5000)
  },
  methods: {
    addClassToEllipsis() {
      if(this.$refs && this.$refs.ellipsis){
        const el = this.$refs.ellipsis;
        el.classList.add("waviy");
      }
    },
    playGame(){
      this.$emit('splash-ended');
      this.$emit('start-music');
    }
  }
};
</script>

<style lang="scss">
.tap-to-begin{
  position: absolute;
  font-size: 100px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  color: #fff;
  text-shadow: 2px 0 0 #000, 0 -2px 0 #000, 0 2px 0 #000, -2px 0 0 #000;
}

.tap-to-begin > div {
  flex-basis: 100%;
  justify-content: center;
  display: flex;
}

.splash-screen {
  font-family: 'Palitoon', sans-serif !important;
  position: absolute;
  //background: #d0f5f0;
  width: 100vw;
  height: 100vh;
  top: 45%; /* Move the top of the div to the vertical center */
  left: 50%; /* Move the left of the div to the horizontal center */
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.splash-screen-title {
  font-size: 100px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  > div {
    margin: 0 10px;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    line-height: 1;
  }
  color: #fff;
  text-shadow: 2px 0 0 #000, 0 -2px 0 #000, 0 2px 0 #000, -2px 0 0 #000;
}

.lottie-beer {
  margin-top: 20px;
  width: 400px;
  height: 200px;
  margin: 0 auto;
  > div {
    margin-top: -125px;
  }
}

.animate__delay-0pt5s {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.animate__delay-1pt5s {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

.animate__delay-2pt5s {
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s;
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s;
}

.animate__delay-3pt5s {
  -webkit-animation-delay: 3.5s;
  animation-delay: 3.5s;
  -webkit-animation-delay: 3.5s;
  animation-delay: 3.5s;
}

.animate__delay-4pt5s {
  -webkit-animation-delay: 4.5s;
  animation-delay: 4.5s;
  -webkit-animation-delay: 4.5s;
  animation-delay: 4.5s;
}

.animate__delay-5pt5s {
  -webkit-animation-delay: 5.5s;
  animation-delay: 5.5s;
  -webkit-animation-delay: 5.5s;
  animation-delay: 5.5s;
}

.waviy {
  position: relative;
  -webkit-box-reflect: below -20px linear-gradient(transparent, rgba(0,0,0,.2));
  font-size: 100px;
  line-height: 1 !important;
}

.waviy span {
  position: relative;
  display: inline-block;
  color: #fff;
  text-transform: uppercase;
  animation: waviy 1s infinite;
  animation-delay: calc(.1s * var(--i));
}
@keyframes waviy {
  0%,40%,100% {
    transform: translateY(0)
  }
  20% {
    transform: translateY(-20px)
  }
}
</style>
