<template>
  <div class="mute">

  </div>
  <SplashScreen v-if="!splashFinished" @splash-ended="splashFinishedEvent" @start-music="startMusic" />
  <div v-if="splashFinished" class="uploads animate__animated animate__fadeInRight" style="font-size: 100px;">

    <div class="nearly-full-width photos" ref="takePhotos">
      <v-card class="mx-auto">

        <template v-slot:title>
          Take a photo to use
        </template>

        <v-card-subtitle>
          Your Privacy Matters! 📸
        </v-card-subtitle>

        <v-card-text>
          Rest assured, your images remain private and are only used within the app. We respect your privacy and never
          store or share your photos.
        </v-card-text>

        <v-file-input label="Upload a photo" :flat="true" accept="image/*" capture="user" prepend-icon=""
          v-model="uploadedFile" @change="handleFileChange">
          <template v-slot:selection>
            Tap here to upload another photo
          </template>
        </v-file-input>
        <div class="preview">
          <div class="item" v-for="previewImage, index in previewImages" :key="index">
            <div class="remove" @click="removeImage(previewImage.key)">
              <i class="fa-regular fa-circle-xmark"></i>
            </div>
            <img class="preview-img" :src="previewImage.src" alt="Preview" />
          </div>
        </div>
        <v-card-actions>
          <v-btn color="#0099CC" block variant="tonal" @click="photoCompleted">{{ btnText }}</v-btn>
        </v-card-actions>

      </v-card>
    </div>

    <div ref="cards" class="nearly-full-width cards hidden">
      <div class="grid">
        <ul class="list">
          <li :class="card.class" :key="card.id" v-for="(card, index) in cards" @click="flipCard(index)">
            <div :class="card.flipped ? 'flip-card flip-card-clicked' : 'flip-card'">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <img class="rest" src="./assets/playing-card-back.png">
                </div>
                <div class="flip-card-back">
                  <img class="rest" src="./assets/playing-card-front-blank.png">
                  <img class="rest" :src="card.cardFront">
                </div>
              </div>
            </div>
          </li>
          <li v-if="showUserCard" ref="userPhoto" class="card ani-2">
            <div ref="userPhotoCard" class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <img class="rest" :src="userPhoto">
                </div>
                <div class="flip-card-back">
                  <img class="rest" src="./assets/playing-card-back.png">
                </div>
              </div>
            </div>
          </li>
        </ul>
        <!--
        <button class="stackOne" @click="initialStackCards">Stack 1</button>
        <button class="stack" @click="stackCards">Stack</button>
        <button class="spread" @click="spreadCards">Spread</button>
        -->
      </div>
    </div>
  </div>
  <ThanksForPlaying v-if="thanksForPlaying" />
</template>

<script>
import SplashScreen from "./components/SplashScreen.vue";
import ThanksForPlaying from "./components/ThanksForPlaying.vue";
import 'animate.css';
import { toRaw } from 'vue';

export default {
  name: "App",
  components: {
    SplashScreen,
    ThanksForPlaying
  },
  data() {
    return {
      splashFinished: false,
      previewImages: [],
      selectedPlayers: null,
      uploadedFile: null,
      filesArray: [],
      showUserCard: true,
      thanksForPlaying: false,
      music: null,
      btnText: "Skip uploading a photo",
      cards: [
        { class: "card", id: 0, flipped: false, stopClick: false, winner: false, cardFront: "./playing-card-front.png" },
        { class: "card", id: 1, flipped: false, stopClick: false, winner: false, cardFront: "./playing-card-front.png" },
        { class: "card", id: 2, flipped: false, stopClick: false, winner: false, cardFront: "./playing-card-front.png" },
        { class: "card", id: 3, flipped: false, stopClick: false, winner: false, cardFront: "./playing-card-front.png" },
        { class: "card", id: 4, flipped: false, stopClick: false, winner: false, cardFront: "./playing-card-front.png" },
        { class: "card", id: 5, flipped: false, stopClick: false, winner: false, cardFront: "./playing-card-front.png" },
        { class: "card", id: 6, flipped: false, stopClick: false, winner: false, cardFront: "./playing-card-front.png" },
        { class: "card", id: 7, flipped: false, stopClick: false, winner: false, cardFront: "./playing-card-front.png" },
        { class: "card", id: 8, flipped: false, stopClick: false, winner: false, cardFront: "./playing-card-front.png" },
        { class: "card", id: 9, flipped: false, stopClick: false, winner: false, cardFront: "./playing-card-front.png" },
        { class: "card", id: 10, flipped: false, stopClick: false, winner: false, cardFront: "./playing-card-front.png" },
        { class: "card ani-1", id: 11, flipped: false, stopClick: false, winner: false, cardFront: "./playing-card-front-blank.png" },
      ]
    };
  },
  computed: {
    userPhoto() {
      return this.previewImages[0] ?? "/rattled.png";
    },
  },
  methods: {
    removeImage(key){
      const indexToRemove = this.filesArray.findIndex(fileArr => fileArr[0].name === key);

      if (indexToRemove !== -1) {
        this.filesArray.splice(indexToRemove, 1);
        console.log("File removed:", key);
      } else {
        console.error("File not found:", key);
      }
    },
    handleFileChange() {
      if (this.uploadedFile) {
        this.filesArray.push(this.uploadedFile);
        console.log(this.filesArray)
      }
    },
    splashFinishedEvent() {
      this.splashFinished = true;
    },
    photoCompleted() {
      const el = this.$refs.takePhotos;
      el.classList.add("animate__animated");
      el.classList.add("animate__fadeOutLeft");
      setTimeout(() => {
        el.classList.add("hidden");
        const secondEl = this.$refs.cards;
        secondEl.classList.remove("hidden")
        secondEl.classList.add("animate__animated");
        secondEl.classList.add("animate__fadeInRight");
        setTimeout(() => {
          this.placePhoto();
        }, 1000)
      }, 500)
    },
    initialStackCards() {
      this.cards.forEach((card) => {
        card.flipped = "flip-card"
      })
    },
    stackCards() {
      this.cards.forEach((card) => {
        setTimeout(() => {
          card.class = 'card';
        }, card.id * 150)
      })
      this.cards.reverse().forEach((card) => {
        setTimeout(() => {
          card.class = 'card';
        }, card.id * 150)
      })
    },
    spreadCards() {
      this.cards.reverse().forEach((card) => {
        setTimeout(() => {
          card.class = `card ani${card.id}`;
        }, card.id * 150);
      });
    },
    flipCard(index) {
      const card = this.cards[index];
      if (card.stopClick) {
        return;
      }
      card.flipped = !card.flipped;
      card.stopClick = true;
      if (card.winner) {
        card.class = `card ani${card.id} shake`;
        this.cards.forEach((innerCard) => {
          if (!innerCard.winner) {
            innerCard.class = 'card';
          }
        });
        this.music.pause();
        new Audio('./true.mp3').play();
        setTimeout(() => {
          new Audio('./laugh.mp3').play();
        }, 500)

        setTimeout(() => {
          this.thanksForPlaying = true;
          this.music.play();
        }, 3000)
      } else {
        new Audio('./false.mp3').play();
      }
    },
    flipCardNoEvent(index) {
      this.cards[index].flipped = !this.cards[index].flipped;
    },
    setRandomCard() {
      const src = this.previewImages[0] ?? "/rattled.png";
      const randomIndex = Math.floor(Math.random() * this.cards.length);
      this.cards[randomIndex].cardFront = src;
      this.cards[randomIndex].winner = true;
    },
    placePhoto() {
      this.flipCardNoEvent(11);
      setTimeout(() => {
        this.$refs.userPhoto.classList.remove("ani-2");
        this.$refs.userPhoto.classList.add("ani-1");
      }, 500)
      setTimeout(() => {
        this.flipCardNoEvent(11);
        this.$refs.userPhotoCard.classList.add("flip-card-clicked");
      }, 2500)
      setTimeout(() => {
        this.cards[11].class = "card";
        this.cards[11].cardFront = "./playing-card-front.png";
        this.showUserCard = false;
      }, 3000)
      setTimeout(() => {
        this.setRandomCard();
        this.spreadCards();
      }, 4000)
    },
    startMusic() {
      this.music = new Audio('./musicloop.mp3');
      this.music.addEventListener('ended', () => {
        // When the song ends, restart the playback
        this.music.currentTime = 0;
        this.music.play();
      });
      this.music.play();
    }
  },
  watch: {
    filesArray: {
      handler(newFilesArray) {
        const files = toRaw(newFilesArray);
        this.previewImages = [];
        files.forEach(fileArr => {
          const file = fileArr[0];
          if (file && file.type.includes('image')) {
            const reader = new FileReader();
            reader.onload = () => {
              const img = new Image();
              img.onload = () => {
                const canvas = document.createElement('canvas');
                const context = canvas.getContext('2d');

                // Calculate new dimensions for the cropped image
                const targetAspectRatio = 0.71;
                const originalAspectRatio = img.width / img.height;

                let newWidth, newHeight;
                if (originalAspectRatio > targetAspectRatio) {
                  newWidth = img.height * targetAspectRatio;
                  newHeight = img.height;
                } else {
                  newWidth = img.width;
                  newHeight = img.width / targetAspectRatio;
                }

                // Calculate the top and bottom offsets for cropping
                const topOffset = (img.height - newHeight) / 2;

                canvas.width = newWidth;
                canvas.height = newHeight;

                // Draw the cropped image on the canvas
                context.drawImage(
                  img,
                  0, topOffset, img.width, img.height - 2 * topOffset,
                  0, 0, newWidth, newHeight
                );

                this.previewImages.push({key: file.name, src: canvas.toDataURL('image/jpeg')});
                this.btnText = "Continue";
              };
              img.src = reader.result;
            };
            reader.readAsDataURL(file);
          }
        })
      },
      deep: true // Watch for changes in nested properties of filesArray
    }
  },
  mounted() {
    document.addEventListener('visibilitychange', () => {
      if (this.music) {
        if (document.hidden) {
          // Page is hidden, pause the audio playback
          this.music.pause();
        } else {
          // Page is visible, resume or start audio playback
          this.music.play().catch(error => {
            // Handle any error that might occur when resuming playback
            console.error('Error:', error);
          });
        }
      }
    });
  }
};

</script>
<style lang="scss">
@font-face {
  font-family: 'Palitoon';
  /* The font family name you want to use */
  src: url('./assets/fonts/palitoon.otf') format('opentype');
}

.music {
  top: 0;
  left: 0;
  position: absolute;
}

html {
  overflow: hidden;
}

body {
  //background: #d0f5f0;
  background-image: url();
  font-family: 'Roboto', sans-serif;
}

.nearly-full-width {
  width: 90vw;
}

.photos {
  max-height: 90vh;

  /*
  .v-card-item {
    height: 8vh !important;
  }

  .v-card-subtitle {
    height: 3vh !important;
  }

  .v-card-text {
    height: 13vh !important;
  }

  .v-input.v-input--horizontal.v-input--center-affix.v-input--density-default.v-locale--is-ltr.v-input--dirty.v-file-input {
    height: 10vh !important;
  }
*/

  .preview {
    display: flex;
    font-size: 0.5em;
    overflow: scroll;
    .item{
      position: relative;
      margin: 0 10px;
      .preview-img {
        max-height: 40vh;
      }
      .remove{
        position: absolute;
        right: 0;
        color: #fff;
        width: 1em;
        height: 1em;
        top: 10px;
        right: 10px;
        i{
          top: 0;
          left: 0;
          position: absolute;
        }
      }
    }
  }

  .v-card-actions {
    height: 10vh !important;
  }
}

.hidden {
  display: none;
}

.uploads {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.file {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.cards {
  height: 90vh;
}

.grid {
  width: 100%;
}

.list {
  height: 75vh;
  position: relative;
  list-style-type: none;
  padding-left: 0;
}

.card {

  transition: all 1s cubic-bezier(0.68, -.55, .265, 1.55);

  perspective: 1000;
  -ms-transform: perspective(1000px);
  -moz-transform: perspective(1000px);
  -ms-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;

  float: left;
  width: 20%;
  height: 25%;

  position: absolute;
  left: 75%;
  top: 75%;
  margin: 3%;

  &:nth-child(5n) {
    margin-right: 0;
  }

  &.ani-2 {
    left: 200%;
    top: 0;
  }

  &.ani-1 {
    left: 0;
    top: 0;
  }

  &.ani0 {
    left: 0;
    top: 0;
  }

  &.ani1 {
    left: 25%;
    top: 0px;
  }

  &.ani2 {
    left: 50%;
    top: 0;
  }

  &.ani3 {
    left: 75%;
    top: 0;
  }

  &.ani4 {
    left: 0;
    top: 30%;
  }

  &.ani5 {
    left: 25%;
    top: 30%;
  }

  &.ani6 {
    left: 50%;
    top: 30%;
  }

  &.ani7 {
    left: 75%;
    top: 30%;
  }

  &.ani8 {
    left: 0%;
    top: 60%;
  }

  &.ani9 {
    left: 25%;
    top: 60%;
  }

  &.ani10 {
    left: 50%;
    top: 60%;
  }

  &.ani11 {
    left: 75%;
    top: 60%;
  }

}

.flip-card {
  background-color: transparent;
  width: 100%;
  height: 100%;
  perspective: 1000px;
  /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card-clicked .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  /* Safari */
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-content: center;

  >img {
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: auto;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  color: black;
}

/* Style the back side */
.flip-card-back {
  transform: rotateY(180deg);
}

.shake {
  animation: shake 0.5s;
  animation-iteration-count: 1;
  animation-iteration-count: infinite;
  height: 100vh;
  margin: 0 auto;
  margin-right: auto;
  display: block;
  max-width: 100%;
  position: absolute;
  left: 0 !important;
  top: 0 !important;
  width: 100vw;
  z-index: 100;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}</style>